<template>
  <div class="bo_config" v-if="boutique">
    
    <h_bo :boutique="boutique.boutique"></h_bo>

    <div v-if="!boutique.boutique.active" class="bar red"><i class="el-icon-warning-outline"></i> Votre restaurant n'est pas en ligne. </div>
    
    <div class="int" :class="{ menu_etat: menu_etat }">
      
      <div class="btn_menu" @click="menu_etat = !menu_etat">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"></path></svg>
        Menu</div>
      
    <nav>
      <router-link @click.native="menu_etat = false" :to="{ name: 'bo_config_boutique' }" tag="div" class="elem" :class="{ active: $route.name === 'bo_config_boutique' }"
                   :data-notif="boutique.boutique.description_notif">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,18H6V14H12M21,14V12L20,7H4L3,12V14H4V20H14V14H18V20H20V14M20,4H4V6H20V4Z" /></svg>
        Votre restaurant</router-link>
      
      <router-link @click.native="menu_etat = false" :to="{ name: 'bo_resto_livraison' }" tag="div" class="elem" :class="{ active: $route.name === 'bo_resto_livraison' }" 
                   :data-notif="boutique.boutique.livraison_retrait_notif">
<!--        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path data-v-7c77e3c8="" d="M7 16.462l1.526-.723c1.792-.81 2.851-.344 4.349.232 1.716.661 2.365.883 3.077 1.164 1.278.506.688 2.177-.592 1.838-.778-.206-2.812-.795-3.38-.931-.64-.154-.93.602-.323.818 1.106.393 2.663.79 3.494 1.007.831.218 1.295-.145 1.881-.611.906-.72 2.968-2.909 2.968-2.909.842-.799 1.991-.135 1.991.72 0 .23-.083.474-.276.707-2.328 2.793-3.06 3.642-4.568 5.226-.623.655-1.342.974-2.204.974-.442 0-.922-.084-1.443-.25-1.825-.581-4.172-1.313-6.5-1.6v-5.662zm-1 6.538h-4v-8h4v8zm15-11.497l-6.5 3.468v-7.215l6.5-3.345v7.092zm-7.5-3.771v7.216l-6.458-3.445v-7.133l6.458 3.362zm-3.408-5.589l6.526 3.398-2.596 1.336-6.451-3.359 2.521-1.375zm10.381 1.415l-2.766 1.423-6.558-3.415 2.872-1.566 6.452 3.558z"></path></svg>-->
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M11.74 13.36L14.14 7.71L13.06 5.5H10.5V4H14L14.73 5.5H21.75L20.75 9H16.44L17.11 10.37C17.69 10.13 18.33 10 19 10C21.76 10 24 12.24 24 15C24 17.76 21.76 20 19 20C16.24 20 14 17.76 14 15C14 13.45 14.71 12.06 15.82 11.15L15 9.5L12.25 16H9.9C9.44 18.28 7.42 20 5 20C2.24 20 0 17.76 0 15C0 12.24 2.24 10 5 10C7.59 10 9.72 11.97 10 14.5H10.58L8.3 9H7.5C7.09 9 6.75 8.66 6.75 8.25C6.75 7.84 7.09 7.5 7.5 7.5H10.25C10.66 7.5 11 7.84 11 8.25C11 8.66 10.66 9 10.25 9H9.97L11.74 13.36M5 11.5C3.07 11.5 1.5 13.07 1.5 15C1.5 16.93 3.07 18.5 5 18.5C6.59 18.5 7.93 17.45 8.36 16H4V14.5H8.47C8.22 12.8 6.76 11.5 5 11.5M19 11.5C18.57 11.5 18.15 11.58 17.77 11.72L19.7 15.68L18.35 16.34L16.5 12.55C15.88 13.18 15.5 14.05 15.5 15C15.5 16.93 17.07 18.5 19 18.5C20.93 18.5 22.5 16.93 22.5 15C22.5 13.07 20.93 11.5 19 11.5Z" /></svg>

        Livraison / retrait</router-link>
      
      <router-link @click.native="menu_etat = false" :to="{ name: 'bo_config_livreurs' }" tag="div" class="elem" :class="{ active: $route.name === 'bo_config_livreurs' }">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M16.5 2.25C17.33 2.25 18 2.92 18 3.75C18 4.58 17.33 5.25 16.5 5.25C15.67 5.25 15 4.58 15 3.75C15 2.92 15.67 2.25 16.5 2.25M20 18C21.11 18 22 18.9 22 20C22 21.11 21.11 22 20 22C19.26 22 18.61 21.6 18.27 21H8.73C8.39 21.6 7.74 22 7 22C5.9 22 5 21.11 5 20C5 18.9 5.9 18 7 18C7.74 18 8.39 18.4 8.73 19H13V13.5L9.73 11.23L6.77 14C6.36 14.37 5.73 14.34 5.35 13.94L2.28 10.65C1.91 10.24 1.93 9.61 2.33 9.23C2.74 8.86 3.37 8.88 3.74 9.28L6.13 11.84L13.26 5.2L13.3 5.18C13.72 4.82 14.35 4.88 14.71 5.3L16.97 8H20C20.55 8 21 8.45 21 9C21 9.55 20.55 10 20 10H16.5C16.15 10 15.85 9.82 15.67 9.56L14.17 7.77L11.64 10.13L14.57 12.18H14.57C14.83 12.36 15 12.66 15 13V19H15.61C16.04 17.13 17.63 15.7 19.58 15.5L18.81 11H20.31L21.39 17.34C20.97 17.12 20.5 17 20 17C18.69 17 17.58 17.84 17.17 19H18.27C18.61 18.4 19.26 18 20 18Z" /></svg>
        Vos livreurs</router-link>
      
      <router-link @click.native="menu_etat = false" :to="{ name: 'bo_config_horaires' }" tag="div" class="elem" :class="{ active: $route.name === 'bo_config_horaires' }"
                   :data-notif="boutique.boutique.horaires_click_n_collect_empty">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12S17.5 2 12 2M7.7 15.5L7 14.2L11 11.9V7H12.5V12.8L7.7 15.5Z" /></svg>
        Vos horaires</router-link>
      
<!--      <router-link @click.native="menu_etat = false" :to="{ name: 'bo_config_clickandcollect' }" tag="div" class="elem" :class="{ active: $route.name === 'bo_config_clickandcollect' }" -->
<!--                   :data-notif="boutique.boutique.clickandcollect_notif">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M10.76,8.69A0.76,0.76 0 0,0 10,9.45V20.9C10,21.32 10.34,21.66 10.76,21.66C10.95,21.66 11.11,21.6 11.24,21.5L13.15,19.95L14.81,23.57C14.94,23.84 15.21,24 15.5,24C15.61,24 15.72,24 15.83,23.92L18.59,22.64C18.97,22.46 19.15,22 18.95,21.63L17.28,18L19.69,17.55C19.85,17.5 20,17.43 20.12,17.29C20.39,16.97 20.35,16.5 20,16.21L11.26,8.86L11.25,8.87C11.12,8.76 10.95,8.69 10.76,8.69M15,10V8H20V10H15M13.83,4.76L16.66,1.93L18.07,3.34L15.24,6.17L13.83,4.76M10,0H12V5H10V0M3.93,14.66L6.76,11.83L8.17,13.24L5.34,16.07L3.93,14.66M3.93,3.34L5.34,1.93L8.17,4.76L6.76,6.17L3.93,3.34M7,10H2V8H7V10" /></svg>-->
<!--        Click & Collect</router-link>-->
      
      <router-link @click.native="menu_etat = false" :to="{ name: 'bo_config_paiements' }" tag="div" class="elem" :class="{ active: $route.name === 'bo_config_paiements' }"
                   :data-notif="boutique.boutique.stripe_notif">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z" /></svg>
        Paiements</router-link>
      
<!--      <router-link @click.native="menu_etat = false" :to="{ name: 'bo_config_abonnement' }" tag="div" class="elem" :class="{ active: $route.name === 'bo_config_abonnement' }"-->
<!--                   :data-notif="boutique.boutique.stripe_abonnement_notif">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12C20,14.4 19,16.5 17.3,18C15.9,16.7 14,16 12,16C10,16 8.2,16.7 6.7,18C5,16.5 4,14.4 4,12A8,8 0 0,1 12,4M14,5.89C13.62,5.9 13.26,6.15 13.1,6.54L11.81,9.77L11.71,10C11,10.13 10.41,10.6 10.14,11.26C9.73,12.29 10.23,13.45 11.26,13.86C12.29,14.27 13.45,13.77 13.86,12.74C14.12,12.08 14,11.32 13.57,10.76L13.67,10.5L14.96,7.29L14.97,7.26C15.17,6.75 14.92,6.17 14.41,5.96C14.28,5.91 14.15,5.89 14,5.89M10,6A1,1 0 0,0 9,7A1,1 0 0,0 10,8A1,1 0 0,0 11,7A1,1 0 0,0 10,6M7,9A1,1 0 0,0 6,10A1,1 0 0,0 7,11A1,1 0 0,0 8,10A1,1 0 0,0 7,9M17,9A1,1 0 0,0 16,10A1,1 0 0,0 17,11A1,1 0 0,0 18,10A1,1 0 0,0 17,9Z" /></svg>-->
<!--        Abonnement</router-link>-->

      <router-link @click.native="menu_etat = false" :to="{ name: 'bo_config_factures' }" tag="div" class="elem" :class="{ active: $route.name === 'bo_config_factures' }">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M3,22L4.5,20.5L6,22L7.5,20.5L9,22L10.5,20.5L12,22L13.5,20.5L15,22L16.5,20.5L18,22L19.5,20.5L21,22V2L19.5,3.5L18,2L16.5,3.5L15,2L13.5,3.5L12,2L10.5,3.5L9,2L7.5,3.5L6,2L4.5,3.5L3,2M18,9H6V7H18M18,13H6V11H18M18,17H6V15H18V17Z" /></svg>
        Factures</router-link>

      <router-link @click.native="menu_etat = false" :to="{ name: 'bo_config_assistance' }" tag="div" class="elem" :class="{ active: $route.name === 'bo_config_assistance' }">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M19.79,15.41C20.74,13.24 20.74,10.75 19.79,8.59L17.05,9.83C17.65,11.21 17.65,12.78 17.06,14.17L19.79,15.41M15.42,4.21C13.25,3.26 10.76,3.26 8.59,4.21L9.83,6.94C11.22,6.35 12.79,6.35 14.18,6.95L15.42,4.21M4.21,8.58C3.26,10.76 3.26,13.24 4.21,15.42L6.95,14.17C6.35,12.79 6.35,11.21 6.95,9.82L4.21,8.58M8.59,19.79C10.76,20.74 13.25,20.74 15.42,19.78L14.18,17.05C12.8,17.65 11.22,17.65 9.84,17.06L8.59,19.79M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" /></svg>
        Assistance</router-link>
      
      <router-link @click.native="menu_etat = false" tag="div" :to="{ name: 'bo_commandes_enattente' }" class="elem">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13,3V9H21V3M13,21H21V11H13M3,21H11V15H3M3,13H11V3H3V13Z" /></svg>
        Panneau de contrôle</router-link>
    </nav>

  
    <div class="right">

      <checklist v-if="!boutique.boutique.active" class=""></checklist>
      
      <div class="right_int">
      <transition name="slide2-fade">
        <router-view></router-view>
      </transition>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import checklist from "@/components/bo/checklist";
// import form_edit from "@/components/bo/form_edit";
import h_bo from "@/components/bo/h_bo";
export default {
  name: "index",
  data() {
    return  {
      boutique: null,
      menu_etat: false,
    }
  },
  watch: {
    getBoutique(n) {
      this.boutique = n
      this.$loader = false
    }
  },
  computed: {
    getBoutique() {
      return this.$store.state.boutique_bo.boutique
    }
  },
  created() {
    this.$loader = true
  },
  mounted() {
    if(this.$store.state.user.user.boutique)
      this.$store.dispatch("boutique_bo/sync");
    
    // this.$http.post('/bo/stripe/account_link_init', { boutique_id: this.$bo_boutique_id }).then((d) => {
    //   console.log(d)
    //   window.open( this.data.url, '_blank' );
    // }).catch(e => console.log(e))
  },
  components: { checklist, h_bo },
}
</script>

<style>

a.cc[target="_blank"] { color: #ec7208; border-bottom: 0; }
a.cc[target="_blank"]:after { background: url(../../../assets/img/ico/SVG/open-in-new.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  display: inline-block;
  height: .8125rem;
  margin: 0 .1875rem 0 .25rem;
  position: relative;
  top: .125rem;
  width: .8125rem;
}
a.qr { color: #ec7208; border-bottom: none; display: flex; cursor: pointer; font-weight: 500; }
a.qr:after { background: url(../../../assets/img/ico/SVG/qrcode.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  display: inline-block;
  height: 1.1rem;
  margin: 0 .1875rem 0 .25rem;
  position: relative;
  top: 0;
  width: 1.1rem;
}

.title_form { font-weight: 500; font-size: 16px; margin-bottom: 26px; }
.bo_config button { width: 200px; height: 40px; margin-top: 32px; border-radius: 6px; background: var(--orange); color: #fff; display: block }
.bo_config button[disabled] { background: var(--gris); cursor: not-allowed; }
.bo_config label { text-transform: none; color: var(--label); font-weight: 400; font-size: 14px; margin-bottom: 0px; display: block }

</style>

<style scoped>
  .bo_config .int { display: flex; flex-direction: column }
  
  .btn_menu { height: 40px; display: flex; align-items: center; font-size: 16px; padding: 0 16px; background: #f5f5f5; border-bottom: 1px solid #eee; }
  
  .int.menu_etat nav { display: block }
  nav  { background: #f7f7f7; padding-top: 16px; display: none; position: relative; z-index: 2 }
  nav .elem { height: 40px; display: flex; align-items: center; margin-left: 16px; padding: 0 16px; margin-bottom: 6px; color: var(--boColor-gris1); font-size: 16px;
    border-radius: 16px 0 0 16px; cursor: pointer; position: relative;
  }
  nav .elem[data-notif]:after { content: ''; width: 10px; height: 10px; border-radius: 100%; background: var(--boRed); margin-left: 10px; }
  nav .elem svg { fill: var(--boColor-gris1); margin-right: 10px; width: 22px; }
  nav .elem.active { color: #f57100; background: #fff; }
  nav .elem.active svg { fill: #f57100 }
  
  .right .right_int { padding: 0 16px; margin-top: 16px; }
  

  @media only screen and (min-width: 800px) {
    
    .bo_config .int { flex-direction: row }
  
    .btn_menu { display: none }
    nav { width: 300px; min-height: 100vh; padding-top: 24px; display: block  }
    
    .right { width: calc(100% - 300px); }
    .right .right_int {  padding: 0 46px; margin-top: 24px; }

    .adjust_width { width: 100%; }

  }
  @media only screen and (min-width: 1600px) {
    .adjust_width { width: 60%; }
  }
</style>