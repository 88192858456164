<template>
  <div class="check" :class="{ no: !check }">
      <svg v-if="check === true" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" /></svg>
      <svg v-else-if="check === false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" /></svg>
      <svg v-else-if="check === 'load'" class="load" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" /></svg>  
    <div><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: "check",
  props: [ 'check' ]
}
</script>

<style scoped>
.check { display: flex; align-items: flex-start; min-height: 34px; padding: 6px 0; font-size: 14px; line-height: 24px; }
.check svg { margin-right: 6px; fill: var(--boVert); flex: 0 0 auto; position: relative; top: -1px }
.check.no svg { fill: var(--boRed) }
.check svg.load { fill: #6772e5; animation: rotate_load 2.5s infinite linear }

@keyframes rotate_load {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}


@media only screen and (min-width: 800px) {

  .check { font-size: 16px; }

}
</style>