<template>
  <div class="checklist" v-if="boutique">
    <div class="left">
      <div class="title">Encore quelques étapes</div>

      <check :check="!boutique.boutique.description_notif">Compléter les <router-link :to="{ name: 'bo_config_boutique' }" href="">informations de base</router-link></check>
<!--      <check :check="!boutique.boutique.horaires_click_n_collect_empty">Configurer vos <router-link :to="{ name: 'bo_config_horaires' }" href="">horaires</router-link></check>-->
      <check :check="!boutique.boutique.livraison_retrait_notif">Configurer la <router-link :to="{ name: 'bo_resto_livraison' }" href="">livraison et/ou le retrait</router-link></check>
<!--      <check :check="!boutique.boutique.stripe_abonnement_notif">Choisir mon <router-link :to="{ name: 'bo_config_abonnement' }" href="">abonnement</router-link></check>-->
      <check :check="!boutique.boutique.stripe_notif">Configurer <router-link :to="{ name: 'bo_config_paiements' }" href="">votre compte Stripe</router-link> pour recevoir vos paiements</check>
      
    </div>
    <div class="right">
      <button_plus txt="Passer en ligne" :rounded="false" @success="enable" :disabled="!boutique.boutique.can_online"></button_plus>
    </div>
  </div>
</template>

<script>
import check from "@/components/global/check";
export default {
  name: "checklist",
  components: { check },
  data() {
    return  {
      boutique: null
    }
  },
  watch: {
    getBoutique(n) {
      this.boutique = n
    }
  },
  computed: {
    getBoutique() {
      return this.$store.state.boutique_bo.boutique
    }
  },
  mounted() {
    this.$store.dispatch("boutique_bo/sync");

    // this.$http.post('/bo/stripe/account_link_init', { boutique_id: this.$store.state.user.user.boutique }).then((d) => {
    //   console.log(d)
    //   window.open( this.data.url, '_blank' );
    // }).catch(e => console.log(e))
  },
  methods: {
    enable() {
      this.$http.post('/bo/boutique/enable').then(() => {
        this.$store.dispatch("boutique_bo/sync");
      })
    }
  }
}
</script>

<style scoped>

.checklist { font-size: 16px; font-weight: 400; background: #fff; padding: 16px 24px; display: flex; flex-direction: column; justify-content: space-between; margin-bottom: 16px;
  box-shadow: 0 4px 30px 0 rgba(0,0,0,.1);
}
.checklist .title { font-size: 20px; margin-bottom: 16px; font-weight: 500; }
.checklist .elem { display: flex; align-items: center; min-height: 34px; padding: 6px 0 }
.checklist .elem svg { margin-right: 6px; fill: var(--boVert); flex: 0 0 auto; }
.checklist .elem.no svg { fill: var(--boRed) }
.checklist .right { padding-top: 16px; }


@media only screen and (min-width: 800px) {
  .checklist { flex-direction: row; padding-left: 46px; padding-top: 24px; padding-bottom: 24px; padding-right: 46px; }
  .checklist .right { padding-top: 6px; }

}
</style>